import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Button from "lib/@cms/components/primitive/Button";
import Divider from "lib/@cms/components/primitive/Divider";

function HomeEquityLoansPage() {
  return (
    <Page pathname="home-equity-loans">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />

            <div className="tw-container">
              <ContentBox title={data.TextBodyA.title}>{data.TextBodyA.description}</ContentBox>
              <Divider className="tw-my-6" />

              {data.TextBodyB && (
                <React.Fragment>
                  <ContentBox title={data.TextBodyB.title}>
                    {data.TextBodyB.description}
                    <ul className="tw-list-disc tw-ml-6 tw-my-3">
                      {data.TextBodyB.textBody?.map((element, index) => {
                        return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                      })}
                    </ul>
                    <Button is="a" href={data.TextBodyB.button?.href} target="_blank">
                      {data.TextBodyB.button?.title}
                    </Button>
                  </ContentBox>
                  <Divider className="tw-my-6" />
                </React.Fragment>
              )}

              {data.TextBodyC && (
                <React.Fragment>
                  <ContentBox title={data.TextBodyC.title}>
                    {data.TextBodyC.description}
                    <ul className="tw-list-disc tw-ml-6 tw-my-3">
                      {data.TextBodyC.textBody?.map((element, index) => {
                        return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                      })}
                    </ul>
                    <Button is="a" href={data.TextBodyC.button?.href} target="_blank">
                      {data.TextBodyC.button?.title}
                    </Button>
                  </ContentBox>
                  <Divider className="tw-my-6" />
                </React.Fragment>
              )}

              {data.TextBodyD && (
                <React.Fragment>
                  <ContentBox title={data.TextBodyD.title}>
                    {data.TextBodyD.description}
                    <ul className="tw-list-disc tw-ml-6 tw-my-3">
                      {data.TextBodyD.textBody?.map((element, index) => {
                        return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                      })}
                    </ul>
                  </ContentBox>
                  <Divider className="tw-my-16 lg:tw-my-24" />
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default HomeEquityLoansPage;
